<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 60%; min-width: 600px">
      <el-row>
        <el-form-item label="手机号">
          <el-input :disabled="true" v-model="aidaUser.userPhone" v-if="aidaUser && aidaUser.userPhone"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="开始时间">
            {{ userLearnPlan.startTimeStr }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间">
            {{ userLearnPlan.endTimeStr }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="是否完成">
          {{ userLearnPlan.hasFinish }}
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="标题">
          <el-input v-model="userLearnPlan.title" placeholder="标题" :disabled="true"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="userLearnPlan.planText" :disabled="true">
        </el-input>
      </el-row>
    </el-form>
    <div class="attach">
      <div v-for="(item, index) in urls" :key="index">
        <img v-if="item.dataType == 'image'" :src="item.url" />
        <video v-if="item.dataType == 'video'" :src="item.url"></video>
      </div>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { userLearnPlanApi } from "@/api/userLearnPlanApi";
import { strToEpoch } from "@/util/timeTool.js";
import { epochToDateString } from "@/util/TimeUtil.js";

export default {
  components: {},
  data() {
    return {
      aidaUser: null,
      urls: [],
      userLearnPlan: {
        id: 0,
        userId: 0,
        courseId: 0,
        title: "",
        planText: "",
        startTime: 0,
        endTime: 0,
        hashFinish: 0,
        attach: [],
      },
    };
  },
  methods: {
    awaitWrap(promise) {
      return promise.then((data) => [null, data]).catch((err) => [err, null]);
    },
    getPostFix(url) {
      let fields = url.split(".");
      if (!fields) {
        return "";
      }
      return fields[fields.length - 1].toLowerCase();
    },
    onGetAidaUser() {
      userLearnPlanApi.getAidaUserById(this.userLearnPlan.userId).then((ret) => {
        let { code, data } = ret;
        if (code == 0 && data) {
          this.aidaUser = data;
          console.log("aida user..", this.aidaUser);
        } else {
          msgLogger.warn("没找到用户...");
        }
      });
    },
    async batchMapCoskeyToCdn(cosList) {
      for (let item of cosList) {
        let [err, ret] = await this.awaitWrap(userLearnPlanApi.getCdnUrl(item.key));
        if (ret && ret.code == 0) {
          let postFix = this.getPostFix(item.key);
          let dataType = "null";
          if (postFix == "png" || postFix == "jpg" || postFix == "jpeg" || postFix == "bmp") {
            dataType = "image";
          }
          if (postFix == "mp4") {
            dataType = "video";
          }
          this.urls.push({ url: ret.data, dataType: dataType });
        }
      }
      console.log("...", this.urls);
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.id) {
      const id = this.$route.query.id;
      userLearnPlanApi
        .getUserLearnPlan(id)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0) {
            this.userLearnPlan = data;
            console.log("...user learn plan", this.userLearnPlan);
            this.batchMapCoskeyToCdn(this.userLearnPlan.attach);
            this.userLearnPlan.startTimeStr = epochToDateString(this.userLearnPlan.startTime);
            this.userLearnPlan.endTimeStr = epochToDateString(this.userLearnPlan.endTime);
            this.onGetAidaUser();
            console.log("...", this.userLearnPlan);
          }
        })
        .catch((err) => {
          msgLogger.error("获取出错.." + err);
        });
    }
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .attach {
    width: 300px;
    img {
      width: 290px;
      margin: 20px;
    }
    video {
      width: 290px;
      margin: 20px;
    }
  }
}
</style>
